<template>
  <div>
    <div class="row-wrap custom-container-xl mb-12 pb-8">
      <div class="login-media hidden-laptop-down">
        <div class="login-media__box">
          <span class="media">{{ loginMethods }}</span>
          <img class="media-arrow" src="@/assets/icon_next.svg" alt />
        </div>
      </div>
      <div class="personal">
        <div class="personal__bg hidden-laptop-down"></div>
        <div class="personal__box">
          <div class="personal__header">
            <div class="personal__title">個人星盤</div>
            <div class="personal__subtitle">
              請輸入您的個人資料，獲取專屬個人星盤。
            </div>
            <div class="personal__notice">
              提醒您，時間填寫越準確，測算結果越精準哦！
            </div>
          </div>
          <div class="personal__info">
            <!-- 背景邊框 -->
            <img class="personal__border tl" src="@/assets/border_tl.svg" alt />
            <img class="personal__border tr" src="@/assets/border_tr.svg" alt />
            <img class="personal__border bl" src="@/assets/border_bl.svg" alt />
            <img class="personal__border br" src="@/assets/border_br.svg" alt />
            <!-- - -->
            <div class="personal__item">
              <span class="item__title">姓名</span>
              <div class="item__input">
                <input
                  type="text"
                  v-model="name"
                  placeholder="請輸入姓名"
                  maxlength="20"
                />
                <!-- 錯誤提示 -->
                <img
                  class="input__error"
                  v-if="!nameCheck"
                  src="@/assets/icon_wrong.svg"
                  alt
                />
                <!-- 字元數錯誤提示 -->
                <div class="input__char__error" v-if="false">
                  <img src="@/assets/icon_wrong.svg" alt />
                  <span>長度最多16個字元</span>
                </div>
              </div>
            </div>
            <div class="personal__item">
              <span class="item__title">性別</span>
              <div class="item__sex">
                <div
                  class="sex-box male"
                  :class="{ activeSex: activeSex == 'Male' }"
                  @click="selectMale"
                >
                  男生
                </div>
                <div
                  class="sex-box female"
                  :class="{ activeSex: activeSex == 'Female' }"
                  @click="selectFemale"
                >
                  女生
                </div>
              </div>
            </div>
            <div class="personal__item">
              <span class="item__title">出生日期</span>

              <div class="item__input" @click.stop="dateModal = true">
                <span>{{ date }}</span>
                <v-dialog
                  ref="dialog"
                  v-model="dateModal"
                  :return-value.sync="date"
                  width="290px"
                  persistent
                >
                  <v-date-picker
                    class="picker date-picker"
                    v-model="date"
                    :first-day-of-week="0"
                    locale="zh-cn"
                    scrollable
                    header-color="rgba(217, 217, 217, 0.25)"
                    color="primary"
                    max="2099-12-31"
                    min="0676-01-01"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="dateModal = false"
                      >取消</v-btn
                    >
                    <v-btn text color="error" @click="$refs.dialog.save(date)"
                      >確認</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>
            <div class="personal__item">
              <span class="item__title">出生時間</span>
              <!-- - -->
              <div class="time-wrap">
                <div class="item__input" @click.stop="timeModal = true">
                  <span>{{ time }}</span>
                  <v-dialog
                    ref="dialog2"
                    v-model="timeModal"
                    :return-value.sync="time"
                    width="290px"
                    persistent
                  >
                    <v-time-picker
                      class="picker time-picker"
                      v-model="time"
                      v-if="timeModal"
                      scrollable
                      ampm-in-title
                      header-color="rgba(217, 217, 217, 0.25)"
                      color="primary"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="error" @click="timeModal = false"
                        >取消</v-btn
                      >
                      <v-btn
                        text
                        color="error"
                        @click="$refs.dialog2.save(time)"
                        >確認</v-btn
                      >
                    </v-time-picker>
                  </v-dialog>
                </div>
                <label class="checkbox-wrap">
                  我不知道
                  <input type="checkbox" v-model="IDontKnow" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="personal__item">
              <span class="item__title"
                >日光節約時間
                <img src="@/assets/日光節約icon.svg" />
              </span>
              <div class="item__sex">
                <div
                  class="sex-box male"
                  :class="{ activeSex: IsDaylight }"
                  @click="
                    IsDaylight = true;
                    DaylightNotice = true;
                  "
                >
                  是
                  <div class="daylight" v-if="DaylightNotice">
                    系統將自動<br />
                    以日光節約時間測算
                  </div>
                </div>
                <div
                  class="sex-box female"
                  :class="{ activeSex: !IsDaylight }"
                  @click="
                    IsDaylight = false;
                    DaylightNotice = false;
                  "
                >
                  否
                </div>
              </div>
            </div>
            <div class="personal__item">
              <span class="item__title">出生地點</span>

              <div class="item__input">
                <v-autocomplete
                  class="select"
                  :items="locationItems"
                  v-model="location"
                  label="Solo field"
                  background-color="transparent"
                  solo
                  hide-details
                ></v-autocomplete>
              </div>
            </div>

            <!-- 新增其他地點 -->
            <div class="personal__item" v-if="showOtherPlace">
              <span class="item__title otherPlace">出生國家</span>
              <div class="item__input">
                <v-autocomplete
                  class="select"
                  :items="$CountryCode"
                  item-text="ChineseName"
                  item-value="Code"
                  v-model="CountryCode"
                  label="請選擇或輸入出生國家"
                  background-color="transparent"
                  solo
                  hide-details
                ></v-autocomplete>
              </div>
            </div>
            <div class="personal__item" v-if="showOtherPlace">
              <span class="item__title otherPlace">出生州省</span>
              <div class="item__input">
                <input
                  type="text"
                  v-model="State"
                  value
                  placeholder="請輸入州或省，例如 New York"
                />
              </div>
            </div>
            <div class="personal__item" v-if="showOtherPlace">
              <span class="item__title otherPlace">出生城市</span>
              <div class="item__input">
                <input
                  type="text"
                  v-model="City"
                  placeholder="請輸入城市，例如 Buffalo"
                />
                <img
                  class="input__error"
                  v-if="!cityCheck"
                  src="@/assets/icon_wrong.svg"
                  alt
                />
                <div class="notice_inputEnglish" v-if="showOtherPlace">
                  州和城市輸入英文可提高準確度。
                </div>
              </div>
            </div>
            <!-- 新增其他地點end -->
          </div>
          <div
            class="personal__submit"
            :class="{ notReady: fileNotApply }"
            @click="createMember"
          >
            開始探索
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: require("@/assets/cat.png"),
      DaylightNotice: false,
      logined: false,
      activeSex: "Male",
      name: null,
      PlatformId: null,
      date: "1990-05-30",
      dateModal: false,
      time: "12:00",
      timeModal: false,
      location: "台北",
      IsDaylight: false,
      loginMethods: localStorage.getItem("loginMethods"),
      locationItems: [
        "基隆",
        "台北",
        // "台北(Google)",
        "新北",
        "桃園",
        "新竹",
        "苗栗",
        "台中",
        "彰化",
        "南投",
        "雲林",
        "嘉義",
        "台南",
        "高雄",
        "屏東",
        "台東",
        "花蓮",
        "宜蘭",
        "澎湖",
        "金門",
        "馬祖",
        "香港",
        "澳門",
        "其他",
      ],
      checklist: [
        { text: "基隆", value: "Keelung" },
        { text: "台北", value: "Taipei" },
        // { text: "台北(Google)", value: "Taipei4Google" },
        { text: "新北", value: "New Taipei" },
        { text: "桃園", value: "Taoyuan" },
        { text: "新竹", value: "Hsinchu" },
        { text: "苗栗", value: "Miaoli" },
        { text: "台中", value: "Taichung" },
        { text: "彰化", value: "Changhua" },
        { text: "南投", value: "Nantou" },
        { text: "雲林", value: "Yunlin" },
        { text: "嘉義", value: "Chiayi" },
        { text: "台南", value: "Tainan" },
        { text: "高雄", value: "Kaohsiung" },
        { text: "屏東", value: "Pingtung" },
        { text: "台東", value: "Taitung" },
        { text: "花蓮", value: "Hualien" },
        { text: "宜蘭", value: "Yilan" },
        { text: "澎湖", value: "Penghu" },
        { text: "金門", value: "Kinmen" },
        { text: "馬祖", value: "Mazu" },
        { text: "香港", value: "Hongkong" },
        { text: "澳門", value: "Macao" },
        { text: "其他", value: "Other" },
      ],
      nameCheck: true,
      showOtherPlace: false,
      countryCheck: true,
      cityCheck: true,
      Gender: "M",
      State: null,
      City: null,
      CountryCode: null,
      fileNotApply: true,
      passLocation: "Taipei",
      IDontKnow: false,
      email: null,
      canClick: true,
    };
  },
  computed: {
    Country() {
      if (this.passLocation != "Other") {
        return null;
      }
      let Country = this.$CountryCode.find((item) => {
        return item.Code == this.CountryCode;
      });
      return Country ? Country.ChineseName : "台灣";
    },
    Zone() {
      if (this.passLocation != "Other") {
        return null;
      }
      let Country = this.$CountryCode.find((item) => {
        return item.Code == this.CountryCode;
      });
      return Country ? parseFloat(Country.Zone) : 8.0;
    },
  },
  watch: {
    location(value) {
      this.checklist.forEach((item) => {
        if (item.text === value) {
          this.passLocation = item.value;
        }
      });
      if (value === "其他") {
        this.showOtherPlace = true;
        this.fileNotApply = true;
      } else {
        this.showOtherPlace = false;
        this.countryCheck = true;
        this.cityCheck = true;

        if (this.nameCheck) {
          this.fileNotApply = false;
        }
        this.City = null;
      }
    },
    Country(value) {
      if (this.location === "其他") {
        if (value === null || value === "") {
          this.countryCheck = false;
          this.fileNotApply = true;
        } else {
          this.countryCheck = true;
          if (this.nameCheck && this.cityCheck) {
            this.fileNotApply = false;
          }
        }
      }
    },
    City(value) {
      if (this.location === "其他") {
        if (value === null || value === "") {
          this.cityCheck = false;
          this.fileNotApply = true;
        } else {
          this.cityCheck = true;
          if (this.nameCheck && this.countryCheck) {
            this.fileNotApply = false;
          }
        }
      }
    },
    name(value) {
      if (value === null || value === "") {
        this.nameCheck = false;
        this.fileNotApply = true;
      } else {
        this.nameCheck = true;
        if (this.countryCheck && this.cityCheck) {
          this.fileNotApply = false;
        }
      }
    },
    IDontKnow(value) {
      if (value === true) {
        this.time = "12:00";
      }
    },
  },
  created() {
    this.email = window.localStorage.getItem("email");
    if (!localStorage.getItem("loginMethods") || !localStorage.getItem("AC")) {
      window.location = this.$store.state.app_return_url;
    }
    // if(localStorage.getItem('token')){ //已經有token 把她趕出去
    //   window.location = this.$store.state.app_return_url;
    // }
  },
  methods: {
    selectMale() {
      this.activeSex = "Male";
      this.Gender = "M";
    },
    selectFemale() {
      this.activeSex = "Female";
      this.Gender = "F";
    },
    createMember() {
      if (this.location === "其他") {
        if (this.City === null || this.City === "") {
          this.cityCheck = false;
        } else {
          this.cityCheck = true;
        }
        if (this.Country === null || this.Country === "") {
          this.countryCheck = false;
        } else {
          this.countryCheck = true;
        }
      }
      if (this.name === null || this.name === "") {
        this.nameCheck = false;
      }

      if (this.nameCheck && this.cityCheck && this.countryCheck) {
        if (this.canClick) {
          this.fileNotApply = true;
          setTimeout(() => {
            this.fileNotApply = false;
          }, 3000);
          this.canClick = false;
          if (this.IDontKnow) {
            this.time = "12:00";
          }

          let BD = this.date.split("-");
          BD[0] = this.$formatNumber(BD[0]);
          BD = BD.join("/");
          const BirthTime = BD + " " + this.time;
          let data = {
            Name: this.name,
            Gender: this.Gender,
            BirthTime: BirthTime,
            BirthPlace: this.passLocation,
            BirthCountry: this.Country ? this.Country : null,
            BirthState: this.State ? this.State : null,
            BirthCity: this.City ? this.City : null,
            BirthCountryCode: this.CountryCode ? this.CountryCode : null,
            BirthTimeZone: this.Zone ? this.Zone : null,
            EncryptPlatformId: window.localStorage.getItem("AC"),
            Platform: window.localStorage.getItem("loginMethods"),
            ProfilePhotoSrc: null,
            FromNode: window.localStorage.getItem("FromNode") || "Website",
            email: this.email,
            IsDaylight: this.IsDaylight,
          };

          window.localStorage.setItem("rn", this.name);
          window.localStorage.setItem("rb", BirthTime);
          if (
            !window.localStorage.getItem("AC") ||
            !window.localStorage.getItem("loginMethods")
          ) {
            window.location = this.$store.state.app_return_url;
            alert("您的瀏覽器不支援 Web Storage，請更換瀏覽器進行註冊喔！");
          } else {
            this.$API
              .Add_MemberInfo(data)
              .then((res) => {
                if (!res) {
                  this.canClick = true;
                  alert(res.Data.ErrMsg);
                  return;
                }
                window.localStorage.removeItem("rn");
                window.localStorage.removeItem("rb");
                window.localStorage.setItem("haveNoData", false);
                window.localStorage.setItem("login_status", true);
                setTimeout(() => {
                  window.location = this.$store.state.app_return_url;
                }, 500);
              })
              .catch(() => {
                alert("輸入的國家或地區找不到，請重新輸入喔");
              });
          }
        }
      } else {
        alert("請輸入必填資料");
        this.canClick = true;
        this.fileNotApply = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/userFile.scss";

.row-wrap {
  display: flex;
  @include laptop {
    justify-content: center;
  }
}

.login-media {
  @include rect(408px, 800px, $theme-color-3, 0);
  box-shadow: 10px 5px 10px 0 rgba(0, 0, 0, 0.09);
  position: relative;

  &::before {
    content: "";
    @include rect(100px, 100px, $theme-color-3, 50%);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    z-index: 1;
  }

  .login-media__box {
    position: relative;
    width: 100%;
    height: 100%;
    @include align(flex-end, center);
    background-color: $theme-color-3;
    z-index: 2;

    .media {
      position: relative;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 1.92px;
      color: $secondary-color-1;
      right: -22px;
    }

    .media-arrow {
      position: relative;
      width: 32px;
      height: 32px;
      right: -30px;
    }
  }
}

.personal {
  margin-left: 122px; //
  @include laptop {
    margin-left: 0px;
  }
  @include mobile {
    width: 100%;
    margin-top: 0px;
  }
  //
  .personal__bg {
    @include rect(688px, 720px, $theme-color-3, 110px);
    box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15), 2px 2px 4px 0 #ffffff;
    position: absolute;
    right: -64px;
    top: -16px;
    z-index: 1;
  }
}
</style>
